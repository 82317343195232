.header {
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  height: 100px; /* Asegura que el header ocupe toda la altura de la ventana */
  margin: 0 !important;
  padding: 0 !important;
}

.logo {
  font-size: 2rem; /* Ajusta el tamaño de la fuente según sea necesario */
}
