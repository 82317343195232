
  
  
  .container-principal input[type="text"],
  .container-principal input[type="file"] {
    color: #8B8B8B; /* Color para los inputs */
  }
  
  .my-account-title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .my-account-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .form-group .form-control {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .form-group .form-control:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .btn-submit {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    color: green;
    font-size: 0.875rem;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
  }
  
  .profile-image-container {
    display: flex;
    flex-direction: column; /* Cambia la dirección del flex a columna */
    align-items: center; /* Centra los elementos horizontalmente */
    margin-bottom: 20px;
  }

  .profile-image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile-logo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    object-fit: cover;
  }


  .create-empresa-container {
    background-color: white;
    max-width: 640px;
    margin: 20px auto;
    box-shadow: 0px 16px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px !important;
    display: flex;
    flex-direction: column;
  }


  .my-account-container input[type="text"],
  .my-account-container input[type="file"],
  .create-empresa-container input[type="text"] {
    color: #8B8B8B; /* Color para los inputs */
  }

  .label-izquierda {
    display: block; /* Asegura que el label ocupe toda la línea */
    text-align: left !important; /* Alinea el texto a la izquierda */
    color: #d6d6d6;
    margin-top: 5px;
  }


  
  
