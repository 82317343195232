.image-upload {
  width: 112px; /* Ancho fijo */
  height: 112px; /* Alto fijo */
  overflow: hidden; /* Ocultar cualquier parte de la imagen que se salga del contenedor */
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para cubrir el contenedor sin distorsionarse */
}
