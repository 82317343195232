.lottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--background);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lottie-animation {
  width: 300px; /* Ajusta el ancho */
  height: 300px; /* Ajusta la altura */
}