/* App.css */

/* Eliminar las variables fijas en :root */
:root {
  --main-color: #349dd0;
  --main-color-rgba: rgba(52, 157, 208, 0.5);
  --secondary-background: #565656;
  --secondary-color: #a6adbb;
  --background: #414545;
  --btn-primary-background: #246f94;
  --color-atention: #8DB789;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/ubuntu/Ubuntu-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


.fonts-mm {
  font-family: 'Moderniz', sans-serif;
}

.App {
  text-align: center;
}


.drawer-side {
  z-index: 50;
}


body {
  background-color: var(--background) !important;
  font-family: 'Ubuntu', sans-serif;
  color: var(--secondary-color);
}

.bg-F9F9F9 {
  background-color: #F9F9F9;
}

.text-3D3D3C {
  color: #3D3D3C;
}

.color-blue {
  color: #0899ef;
}

.bg-dark {
  background-color: var(--secondary-background);
}


.container-principal {
  max-width: 640px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-background);
  position: relative;
}

.container-mayor {
  max-width: 860px;
  margin: 0 auto;
  padding: 40px 100px;
  border-radius: 8px;
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-background);
  position: relative;
}

.container-mayor label{
  text-align: left !important;
  display: block !important;
  color: var(--secondary-color) !important;
  font-size: 12px !important;
}

.container-mayor input{
  color: var(--background); /* Color para los inputs */
}

.container-mayor textarea{
  color: var(--background);
  padding: 8px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-row > div {
  flex: 1;
  margin-right: 10px;
}

.form-row > div:last-child {
  margin-right: 0;
}


input[type="text"], input[type="file"], input[type="password"], input[type="email"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.container-principal input[type="text"],
.container-principal input[type="file"] {
  color: #8B8B8B; /* Color para los inputs */
}

.btn-azul {
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px 70px; /* Mantén el padding que deseas */
  margin: 20px auto; /* Centra el botón horizontalmente */
  display: block; /* Asegura que el botón sea un bloque */
}
  
.ico-cerrar-form{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.input-group {
  position: relative; /* Posicionamiento relativo para el contenedor */
}

.input-group .form-control {
  width: 100%; /* Asegúrate de que el input ocupe todo el ancho disponible */
  padding-right: 40px; /* Espacio para el icono */
  box-sizing: border-box; /* Incluye el padding en el ancho total */
}

.input-group .input-icon {
  position: absolute; /* Posicionamiento absoluto para el icono */
  right: 10px; /* Ajusta la posición a la derecha */
  top: 50%; /* Centrar verticalmente */
  transform: translateY(-50%); /* Ajustar el centrado */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el icono */
}

.container-principal input[type="date"] {
  width: 100%; /* Asegúrate de que el input ocupe todo el ancho disponible */
  padding: 8px; /* Espaciado interno para mayor comodidad */
  border-radius: 4px; /* Bordes redondeados */
  background-color: white; /* Color de fondo */
  color: var(--secondary-color); /* Color del texto */
}

.btn-volver{
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
}


@media (max-width: 768px) {
  .container-mayor {
      max-width: 96%;
      margin: 0 auto;
      padding: 30px 15px;
  }
  .btn-azul{
    padding: 10px !important;
  }
  .btn-volver{
    left: 0px;
    top: -50px;
  }
}
