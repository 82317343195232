.dashboard-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .dashboard-container h1 {
    color: #333;
  }
  
  .dashboard-container p {
    color: #666;
  }