/* .hover-underline {
  position: relative;
  color: inherit;
  text-decoration: none;
}

.hover-underline::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -5px;
  background: var(--btn-primary-background);
  transition: width 0.3s ease-in-out;
}

.hover-underline:hover::after {
  width: 100%;
} */

.btn-header {
  align-items: center;
  border: 1px solid #868686;
  border-radius: 20px;
  color: var(--border);
  display: flex;
  font-size: 14px;
  margin-right: 20px;
  padding: 8px 22px;
  text-decoration: none;

}

.btn-icon {
  margin-right: 8px; /* Espacio entre el icono y el texto */
  width: 16px;
}

.btn-icon-right {
  margin-left: 8px; /* Espacio entre el texto y la flecha */
  width: 5px;
}

.active {
  color: var(--main-color);
}

.mr-30 {
  margin-right: 30px;
}

.logo {
  /* height: 56px; */
  width: 200px !important;
}

.btn-settings {
  max-width: 26px;
  transition: transform 0.6s ease-in-out;
}

.btn-settings:hover {
  transform: rotate(66deg);
}

.navbar {
  background-color: var(--background);
  display: flex;
  flex-direction: column; /* Colocar logo y menú en columnas */
  width: 100%;
}

.logo-container {
  display: flex;
  justify-content: center; /* Centrar el logo de la plataforma */
  width: 100%;
}

.logo-tubim {
  width: 100%; /* Usar el 100% del ancho */
  max-width: 300px; /* Limitar el ancho máximo si es necesario */
}

.menu-header {
  justify-content: space-between; /* Espacio entre logo-info y links-container */
  align-items: center; /* Alinear verticalmente */
  width: 100%;
  padding: 10px 30px; /* Añadir padding para un mejor espaciado */
}

.menu{
  padding: 0 !important;
}

.logo-info {
  position: relative; /* Añadir posición relativa al contenedor */
  display: flex;
  align-items: center; /* Alinear verticalmente */
  margin-left: 30px; /* Margen a la izquierda */
  width: 300px; /* Ancho fijo para logo-info */
}

.logo-empresa {
  width: 100px;
  border-radius: 20px;
  border: 2px solid white !important;
}

.logo-empresa-container{
  display: flex;
  align-items: center;
  position: relative;
}

.logo-empresa{
  width: 100px;
  border-radius: 20px;
  border: 2px solid #868686;
}

.empresa-info {
  display: flex;
  flex-direction: column; /* Alinear nombre y email verticalmente */
  margin-left: 20px; /* Espacio entre el logo y la información de la empresa */
}

.empresa-info p{
  font-size: 11px;
  margin-top: 6px;
}

.nombre-empresa{
  font-size: 16px;
  font-weight: bold;
}

.email-empresa{
  font-size: 14px;
}

.logo-usuario {
  position: absolute; /* Posicionar absolutamente */
  bottom: -10px; /* Alinear al fondo */
  right: -15px; /* Alinear a la izquierda */
  width: 40px;
  height: 40px; /* Asegúrate de que tenga la misma altura que el ancho para ser circular */
  border-radius: 50%; /* Mantener el borde redondeado */
  object-fit: cover; /* Asegura que la imagen se recorte y mantenga la proporción */
  overflow: hidden; /* Asegura que el contenido que sobresale se oculte */
  border: 2px solid white;
  margin-left: 10px; 
}

.links-container {
  display: flex;
  align-items: center; /* Alinear verticalmente los enlaces */
  margin-right: 100px; 
  max-width: 500px;
}

/* Añade un margen superior al contenedor principal para evitar la superposición */
.main-content {
  margin-top: 80px; /* Ajusta este valor según la altura de tu navbar */
}

.navbar .logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px; /* Añadido margen superior */
}

.navbar .links-container {
  display: flex;
  align-items: center; /* Alinear verticalmente los enlaces */
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px; /* Ajusta el margen según sea necesario */
}

.logo {
  width: 100px;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Asegúrate de que el z-index sea mayor que el del footer */
}

.active .btn-icon-right {
  margin-left: 16px;
}


@media (max-width: 768px) {
  .logo-info {
    flex-direction: column; /* Cambiar a columna */
    align-items: center; /* Centrar horizontalmente */
    width: 100%; /* Asegurar que ocupe todo el ancho */
    margin: 0 !important;
    margin-bottom: 30px !important;
  }

  .logo-empresa-container {
    flex-direction: column; /* Cambiar a columna */
    align-items: center; /* Centrar horizontalmente */
    width: 100%; /* Asegurar que ocupe todo el ancho */
  }

  .empresa-info {
    align-items: center; /* Centrar horizontalmente */
    margin-top: 20px; /* Espacio entre los logos y la información */
    text-align: center; /* Centrar el texto */
    margin-left: 0 !important;
  }

  .logo-usuario {
    margin-top: 10px; /* Espacio entre los logos */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
    bottom: -10px; /* Alinear al fondo */
    right: 25%; /* Alinear a la izquierda */
  }
}
