.home-container {
    font-family: Arial, sans-serif;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .logo {
    font-size: 2.5em;
    color: #00aaff;
  }
  
  .tagline {
    font-size: 1.2em;
    color: #aaa;
  }
  
  .main-content h2 {
    font-size: 2em;
    margin: 10px 0;
  }
  
  .main-content h3 {
    font-size: 1.5em;
    color: #00aaff;
    margin: 10px 0;
  }
  
  .main-content p {
    font-size: 1em;
    margin: 10px 0;
  }
  
  .buttons {
    margin: 20px 0;
  }
  
  .btn {
    background-color: #00aaff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .btn:hover {
    background-color: #0088cc;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .feature {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
  }
  
  .feature h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 1em;
  }
  
  .footer {
    margin-top: 20px;
    font-size: 0.8em;
    color: #aaa;
  }
  
  @media (max-width: 768px) {
    .features {
      flex-direction: column;
      align-items: center;
    }
  
    .feature {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  .main-content {
    display: flex;
  }
  
  .left-content, .right-content {
    width: 50%;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .left-content {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  .left-content img {
    width: 70%;
    height: auto;
  }
  
  .right-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea el contenido a la izquierda */
    margin-top: 100px;
  }
  
  .right-content * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .btn-tubim-one {
      border: 1px solid white;
      border-radius: 30px;
      color: #fff;
      padding: 10px 25px;
      margin: 5px;
      cursor: pointer;
      font-size: 0.8em;
    }

  .btn-tubim-two {
    background-color: white;
    border-radius: 30px;
    padding: 10px 25px;
    color: #414545;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 0.8em;
  }
