  
.skeleton-container-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--background); /* Color de fondo del skeleton */
    border-radius: 8px;
    max-width: 640px; /* Establece el ancho máximo */
    margin: auto;
  }
  

.skeleton-proyecto {
    background-color: #333;
    border-radius: 8px;
    padding: 16px;
    width: 300px;
    margin: 16px;
  }
  
  .skeleton-header {
    display: flex;
    justify-content: space-between;
  }
  
  .skeleton-avatar, .skeleton-close {
    width: 40px;
    height: 40px;
    background-color: var(--secondary-color);;
    border-radius: 50%;
  }
  
  .skeleton-body {
    margin-top: 16px;
  }
  
  .skeleton-title, .skeleton-email, .skeleton-reports, .skeleton-rating {
    height: 20px;
    background-color: var(--secondary-color);;
    margin-bottom: 8px;
  }
  
  .skeleton-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .skeleton-button{
    background-color: var(--secondary-color);
    border-radius: 8px;
    height: 80px !important;
    max-width: 250px;
  }

.skeleton-titulo {
   background-color: var(--secondary-color);
   height: 40px !important;
   width: 150px;
   z-index: 9999;
   
  }

.skeleton-proyectos-container {
  display: flex;
  justify-content: space-between; /* Espacio entre los elementos */
  align-items: flex-start; /* Alinea los elementos al inicio del contenedor */
}

@media (max-width: 768px) {
  .skeleton-proyectos-container {
    flex-direction: column;
    max-width: 100%;
  }
}
