@keyframes borderAnimation {
  0% {
    border-color: #ccc;
  }
  50% {
    border-color: #ff4d4d; /* Tonalidad de rojo */
  }
  100% {
    border-color: #ccc;
  }
}

.modal {
  backdrop-filter: blur(5px); /* Efecto de desenfoque en el fondo */
}

.modal-box {
  border: 4px solid transparent; /* Cambiar a transparente para que la animación sea visible */
  border-radius: 8px; /* Ajusta el radio según sea necesario */
  padding: 20px; /* Espaciado interno */
  background-color: var(--background); /* Fondo oscuro con opacidad */
  position: relative; /* Para el borde animado */
  animation: borderAnimation 1.5s infinite; /* Aplicar la animación al modal-box */
  color: white; /* Cambiar el color del texto a blanco para mejor contraste */
}

.link-proyecto{
  text-decoration: none;
  border-radius: 30px;
  color: #414546;
  margin: 0 auto;
}

.line-height-1 {
    line-height: 1.1; /* Ajusta el valor según sea necesario */
}

.circular {
    display: flex; /* Agregar display flex */
    align-items: center; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
    border-radius: 50%;
    border: 3px solid #ccc;
    cursor: pointer;
    padding: 1px; /* Puedes ajustar este padding si es necesario */
    font-size: 20px; /* Tamaño de fuente pequeño */
    width: 33px;
    height: 33px;
}

.datos-proyecto{
  min-height: 120px;
}

@media (max-width: 768px) {
  .input-mobile,
  .select-mobile {
    max-width: 45%;
  }
}