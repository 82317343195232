.skeleton-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--background);
    border-radius: 8px;
    max-width: 640px;
    margin: auto;
  }
  
  .skeleton-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .skeleton-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    margin-right: 10px;
  }
  
  .skeleton-title {
    height: 20px;
    width: 200px;
    background-color: var(--secondary-color);
    border-radius: 4px;
  }
  
  .skeleton-body {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .skeleton-field {
    height: 15px;
    background-color: var(--secondary-color);
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .skeleton-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .skeleton-button {
    width: 150px;
    height: 40px;
    background-color: var(--secondary-color);
    border-radius: 4px;
  }