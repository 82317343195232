/* Glass Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px); /* Menos difuminado */
  -webkit-backdrop-filter: blur(6px); /* Menos difuminado */
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.glass-background {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px); /* Menos difuminado */
  -webkit-backdrop-filter: blur(6px); /* Menos difuminado */
}