/* src/components/Skeleton.css */
.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: var(--secondary-background); /* Color de fondo del skeleton */
  border-radius: 8px;
  max-width: 640px; /* Establece el ancho máximo */
  margin: auto;
}

.skeleton-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--secondary-color); /* Color del avatar */
  margin-bottom: 20px;
}

.skeleton-fields {
  display: flex;
  justify-content: space-between; /* Espacio entre los campos */
  width: 100%; /* Asegúrate de que ocupe el ancho completo */
  margin-bottom: 15px;
  margin-top: 15px;
}

.skeleton-field {
  flex: 1; /* Permite que los campos ocupen el mismo espacio */
  margin-right: 10px; /* Espacio entre los campos */
}

.skeleton-field:last-child {
  margin-right: 0; /* Elimina el margen derecho del último campo */
}

.skeleton-label {
  height: 15px;
  background-color: var(--secondary-color); /* Color de la etiqueta */
  border-radius: 4px;
  margin-bottom: 5px;
  width: 80px;
}

.skeleton-input {
  height: 30px;
  background-color: var(--secondary-color); /* Color del input */
  border-radius: 4px;
}

.skeleton-button {
  width: 100%;
  max-width: 150px;
  height: 40px;
  background-color: var(--secondary-color); /* Color del botón */
  border-radius: 4px;
  margin-top: 40px;
}

.skeleton-divider {
  height: 1px; /* Altura del divisor */
  background-color: var(--secondary-color); /* Color del divisor */
  width: 100%; /* Asegúrate de que ocupe el ancho completo */
  margin: 60px 0 20px 0;
}

.skeleton-empresa {
  width: 80px; /* Ajusta el ancho según sea necesario */
  height: 80px; /* Altura del logo de la empresa */
  border-radius: 8px; /* Borde redondeado */
  background-color: var(--secondary-color); /* Color del fondo */
  margin-bottom: 15px; /* Espacio debajo del logo */
}

.skeleton-field-empresa {
  display: flex; /* Alinea el label y el input */
  flex-direction: column; /* El label estará arriba del input */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
}

.skeleton-label-empresa {
  height: 15px;
  background-color: var(--secondary-color); /* Color de la etiqueta */
  border-radius: 4px;
  margin-bottom: 5px;
  width: 80px; /* Mantiene el ancho del label */
}

.skeleton-input-empresa {
  height: 30px;
  background-color: var(--secondary-color); /* Color del input */
  border-radius: 4px;
  width: 100%; /* Asegura que el input ocupe todo el ancho del contenedor */
}

